import { DEFAULT_MAX_SPONSORED_CONTENT, leadTextAttributes, leadTextDefaults } from 'shared_server_client/constants'

export const INVALID_IMAGE_URL_MESSAGE = 'Please double check the url is linked to an image'
export const ARTICLE_FETCH_ERROR_MESSAGE = 'Oh no! We had trouble pulling in this url. Try adding a title below and clicking Save & Schedule.'
export const SERVER_ERROR_MESSAGE = 'Something went wrong, try again or contact Customer Success'
export const CONTENT_ALREADY_EXISTS_MESSAGE = 'A content is already scheduled for that period/location, use new period/location or update existing content.'
export const CONTENT_IMAGE_ALREADY_EXISTS_MESSAGE = 'An image is already scheduled for that period/location, use new period/location or update existing image.'
export const CONTENT_TEXT_ALREADY_EXISTS_MESSAGE = 'A text is already scheduled for that period/location, use new period/location or update existing text.'
export const CONTENT_ALREADY_EXISTS = 'CONTENT_ALREADY_EXISTS'

export const SCHEDULED_CONTENT_NOT_ACTIVE_MESSAGE = 'You do not have scheduled content toggled on for your template.'
export const ARTICLE_DESCRIPTION_CHANGE_MESSAGE = 'This description will be displayed in its entirety since it has been edited. Check your preview to see the new description length.'

export const CUSTOM_DATE_RANGE_KEY = 10
export enum IMAGE_MAX_WIDTH {
  BANNER = 600,
  SQUARE = 250,
}

export enum ImageDisplayType {
  FIXED = 'Fixed',
  DYNAMIC = 'Dynamic',
}

export enum ImageUploadType {
  BANNER = 'Banner',
  SQUARE = 'Square',
}

export const ContentTypeToDescription = {
  scheduledArticle: 'Scheduled Article',
  scheduledImage: 'Scheduled Image',
  scheduledText: 'Scheduled Text',
}

export const EmptyScheduledContentEntityObject = {
  id: null,
  contentType: '',
  endDate: null,
  startDate: null,
  location: null,
  imageUrl: '',
  linkUrl: '',
}

export const InitialScheduledContentModalState = {
  activeCommunity: {},
  currentTime: new Date(),
  descriptions: '',
  dirtyFields: [],
  endDate: null,
  errorMessage: '',
  hasSectionAccess: false,
  imageDisplayType: ImageDisplayType.DYNAMIC,
  imageName: '',
  imageUrl: '',
  isDirty: false,
  isEditingImage: false,
  hasImageGalleryAccess: false,
  isLoading: false,
  isSaving: false,
  isSuperUser: false,
  isUploading: false,
  location: null,
  locationOptions: [],
  maxSponsoredPosts: DEFAULT_MAX_SPONSORED_CONTENT,
  modalErrorMessage: '',
  originalUrl: '',
  retrievedUrl: '',
  scheduleErrorMessage: '',
  sections: [],
  selectedLocation: null,
  selectedSection: null,
  showDescriptionChangeMessage: false,
  showImageUploadButtons: false,
  showSaveConfirmationModal: false,
  showSegments: false,
  sourceUrl: '',
  startDate: null,
  styles: '',
  typeId: null,
  useUrl: false,
  validUrl: false,
}

export const ClearScheduledContentDataState = {
  descriptions: '',
  dirtyFields: [],
  endDate: null,
  errorMessage: '',
  imageName: '',
  imageUrl: '',
  isDirty: false,
  isEditingImage: false,
  isLoading: false,
  isSaving: false,
  isSuperUser: false,
  isUploading: false,
  isValid: false,
  location: null,
  selectedLocation: null,
  modalErrorMessage: '',
  scheduleErrorMessage: '',
  originalUrl: '',
  retrievedUrl: '',
  sourceUrl: '',
  startDate: null,
  showDescriptionChangeMessage: false,
  showSaveConfirmationModal: false,
  styles: '',
  typeId: null,
  useUrl: false,
  validUrl: false,
  selectedSection: null,
}

export const DefaultStaticTextStyles = JSON.stringify({
  [leadTextAttributes.borderColor]: leadTextDefaults.borderColor,
  [leadTextAttributes.borderWidth]: leadTextDefaults.borderWidth,
  [leadTextAttributes.color]: leadTextDefaults.color,
  [leadTextAttributes.fontSize]: leadTextDefaults.fontSize,
  [leadTextAttributes.fontStyle]: leadTextDefaults.fontStyle,
  [leadTextAttributes.fontWeight]: leadTextDefaults.fontWeight,
})

export enum DateRangeFilter {
  SELECT = 'Select',
  YESTERDAY = 'Yesterday',
  TODAY = 'Today',
  LAST_SEVEN_DAYS = 'Last 7 Days',
  LAST_WEEK = 'Last Week',
  LAST_30_DAYS = 'Last ~30 Days',
  LAST_MONTH = 'Last Month',
  THIS_WEEK = 'This Week',
  NEXT_WEEK = 'Next Week',
  NEXT_MONTH = 'Next Month',
  CUSTOM_DATE_RANGE = 'Custom Date Range',
}

export const CONTROL_CONTAINER = 'container'
export const CONTROL_FETCH_BUTTON = 'fetch_button'

export enum FEATURE_TYPE {
  LEAD = 'Lead',
  SPONSORED_POST = 'SponsoredPost',
}
