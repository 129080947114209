import {GridColumn } from '@progress/kendo-react-grid'
import { DateTimeCell } from 'components/gridcells/datetime'
import { HeaderComponent } from 'components/header/component'
import { RasaContext } from 'context'
import { EditCellProps } from 'generic/editCell'
import { JsonCell } from 'generic/jsonCell'
import { RasaDataGrid } from 'generic/rasaDataGrid'
import * as GenericUtils from 'generic/utility'
import { capitalize } from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import * as Flash from '../flash'

import { DEFAULT_IMPORT_HISTORY_PAGE_SIZE, RUN_TYPE } from './constants'

import './_styles.scss'
import { Roles } from 'shared/constants'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'

export class ImportHistoryComponentToConnect extends Component<any, any> {
  public static contextType = RasaContext
  constructor(props: any) {
    super(props)
    this.state = {
      communityId: null,
      isSuperUser: false,
      isCommunityAdminUser: false,
    }
  }

  public componentDidMount() {
    this.context.user.init().then(({ person, activeCommunity }) => {
      this.setState({
        communityId: activeCommunity.communityId,
        isSuperUser: activeCommunity.role === Roles.super_admin,
        isCommunityAdminUser: activeCommunity.role === Roles.community_admin,
      })
    })
  }

  public render() {
    return (
      <div className="segmentation-page">
        <div className="header-wrapper">
        <HeaderComponent
              icon={'sources'}
              title={'CONTACTS'}
              subTitle={'Import History'}
              description={[
                'View import history of CSV uploads.',
              ]}
            />
          </div>
          <RasaDataGrid
                // Rasa Properties for entity, datasetName etc
              entityName=""
              datasetName="importHistory"
              editDefaultState={true}
              datasetParams={[
              { param: 'runType', value: RUN_TYPE},
              {param: 'pageSize', value: DEFAULT_IMPORT_HISTORY_PAGE_SIZE},
              ]}
              sortable={false}
              pageable={false}
              data={[]}>
              <GridColumn title="Type" field="type" editable={false} width={100}/>
              <GridColumn title="File Name" field="run_attributes" editable={false}
              width={150} cell={this.FileNameCell}/>
              <GridColumn title="Uploaded By" field="email_address" editable={false}
              width={150}/>
              <GridColumn title="Action Type" field="run_attributes" editable={false}
              width={100} cell={this.ActionTypeCell}/>
              <GridColumn title="Status" field="status" editable={false} width={100}/>
              <GridColumn title="Start Date" field="start_date" editable={false}
              cell={DateTimeCell} width={200}/>
              <GridColumn title="Created" field="run_attributes" editable={false}
                width={80} cell={this.CreatedCountCell}/>
              <GridColumn title="Updated" field="run_attributes" editable={false}
                width={80} cell={this.UpdatedCountCell}/>
              <GridColumn title="Invalid Email" field="run_attributes" editable={false}
                width={100} cell={this.InvalidEmailCountCell}/>
              <GridColumn title="End Date" field="end_date" editable={false} cell={DateTimeCell} width={150}/>
              {(this.state.isSuperUser || this.state.isCommunityAdminUser) &&
                <GridColumn title="File" field="run_attributes" editable={false} cell={this.FileDownloadCell} width={100}/>}
              {this.state.isSuperUser &&
                <GridColumn title="Log" field="run_attributes" editable={false} cell={this.LogDownloadCell} width={100}/>}
          </RasaDataGrid>
    </div>
      )
  }

  protected FileNameCell = (props: EditCellProps) => {
    if (props.dataItem.system_name) {
      return <td>{props.dataItem.system_name}</td>
    } else {
      return <JsonCell fieldName="run_attributes" displayfieldName="file_name" {...props}></JsonCell>
    }
  }

  protected ActionTypeCell = (props: EditCellProps) => {
    return <JsonCell getCustomFieldName={this.getCustomActionType} fieldName="run_attributes" displayfieldName="upload_type" {...props}></JsonCell>
  }

  protected InvalidEmailCountCell = (props: EditCellProps) => {
    return <JsonCell getCustomFieldName={this.getCustomInvalidEmail} fieldName="run_attributes" displayfieldName="result" {...props}></JsonCell>
  }

  protected CreatedCountCell = (props: EditCellProps) => {
    return <JsonCell getCustomFieldName={this.getCustomCreatedCount} fieldName="run_attributes" displayfieldName="result" {...props}></JsonCell>
  }

  protected UpdatedCountCell = (props: EditCellProps) => {
    return <JsonCell getCustomFieldName={this.getCustomUpdatedCount} fieldName="run_attributes" displayfieldName="result" {...props}></JsonCell>
  }

  protected FileDownloadCell = (props: EditCellProps) => {
    const run_attributes = props.dataItem.run_attributes ? GenericUtils.tryParseJSON(props.dataItem.run_attributes) : {}
    const bucket_name = props.dataItem.type === 'FTP' && props.dataItem.status === 'Failed' ? run_attributes.bucket : ""
    return run_attributes.file_path ? <td>
      <div onClick={() => this.downloadFile(run_attributes.file_path, run_attributes.file_name, props.dataItem.type, bucket_name)}>
        Download File
      </div></td> : <td></td>
  }

  protected LogDownloadCell = (props: EditCellProps) => {
    const run_attributes = props.dataItem.run_attributes ? GenericUtils.tryParseJSON(props.dataItem.run_attributes) : {}
    const file_type = props.dataItem.type === 'FTP' ? 'Integration Sync' : props.dataItem.type
    return run_attributes.log_file_name ? <td>
      <div onClick={() => this.downloadFile(run_attributes.log_file_name, run_attributes.log_file_name, file_type, run_attributes.log_file_bucket || "")}>
        Download Log
      </div></td> : <td></td>
  }

  private downloadFile = (file_path, file_name, type, bucket_name) => {
    this.setState({
      isLoading: true,
    }, () => {
      const payload = {
        file_path,
        file_type: type,
        bucket: bucket_name,
      }
      const url: string = `${AjaxWrapper.getServerUrl()}/${this.state.communityId}/file-download`
      return AjaxWrapper.ajax(url, HttpMethod.POST, payload)
      .then((e) => {
        if (e.statusCode === 200 || e.statusCode === 202) {
          const save_file_name = file_name ||  (e.data ? e.data.file_name : file_path.split('/').pop())
          GenericUtils.downloadTextFile(e.payload.body, 'csv', save_file_name)
        } else {
          this.context.store.dispatch(Flash.showFlashError('\'Oops! There was a problem downloading file. Please try again.'))
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('ERROR: ', error)
      })
    })
  }

  private getCustomActionType = (actionType: string) => {
    return capitalize(actionType === 'add' ? 'subscribe' : actionType)
  }

  private getCustomInvalidEmail = (result: any) => {
    return result.invalidEmailCount ? result.invalidEmailCount : 0
  }

  private getCustomCreatedCount = (result: any) => {
    return result.createdCount ? result.createdCount : 0
  }

  private getCustomUpdatedCount = (result: any) => {
    return result.updatedCount ? result.updatedCount : 0
  }
}

export const ImportHistoryComponent = connect(
  null,
  {
    push: Router.push,
  },
)(ImportHistoryComponentToConnect)
