import React from 'react'
import { AbstractPanelBar } from './component'
import './styles.css'
import { PanelBarOption } from './types'
import { Upgrade } from '../icons/upgrade'
import { CarrotUp } from '../icons/carrot-up'
import { CarrotDown } from '../icons/carrot-down'


const upgradeIcon = <Upgrade svgwidth={30} svgheight={30} />
const carrotUpIcon = <CarrotUp black svgwidth={15} svgheight={15} />
const carrotDownIcon = <CarrotDown black svgwidth={15} svgheight={15} />

export class DesktopPanelBar extends AbstractPanelBar {

  constructor(props) {
    super(props)
    const firstCategory = props.itemList.length > 0 ? props.itemList[0].category : ''
    this.state = {
      selectedItem: this.props.selectedItem,
      selectedMenu: {
        [firstCategory]: true,
      }
    }
    this.toggleCategory = this.toggleCategory.bind(this)
  }
  public render() {
    return <div className="panel-wrap">
      <div className="panel-panelbar">
        <div className="panel-section-header-text panel-indent">
          {this.props.title}
          <span className="header-icon">
            {this.props.headerIcon ? this.props.headerIcon : ''}
          </span>
        </div>
        <div className="panel-detail-text panel-left-indent">
          {this.props.subTitle}
        </div>
        <div className="panel-options">
          {this.outputOptions()}
        </div>
      </div>
    </div>
  }


   private toggleCategory(category: string) {
    this.setState((prevState) => ({
      selectedMenu: {
        ...prevState.selectedMenu,
        [category]: !prevState.selectedMenu[category],
      },
    }))
  }

  private outputOptions() {
    let currentCategory: string = ''
    function _getCategory(item: PanelBarOption) {
      if (currentCategory !== item.category) {
        currentCategory = item.category
        return <div className="panel-category">{item.category}</div>
      } else {
        return <div></div>
      }
    }

    const _getCategoryWithIcon = (item: PanelBarOption) => {
      if (currentCategory !== item.category) {
        currentCategory = item.category
        return (
          <div className="panel-category-container justify-content-between">
            <div className='d-flex align-items-center'>
              <div className="panel-category-icon">{item.icon}</div>
              <div className="panel-category-text">{item.category}</div>
            </div>
            <span className="toggle-icon">
            {this.state.selectedMenu[item.category] ? carrotUpIcon : carrotDownIcon}
          </span>
          </div>
        )
      } else {
        return null
      }
    }

    return this.props.itemList
      .filter((item) => this.showOption(item))
      .map((item: PanelBarOption) => {
        if (item.isMenu) {
          return (
            <>
            <div key={item.name} className="panel-category-item clickable-item"
                 onClick={() =>this.toggleCategory(item.category)}>
              {_getCategoryWithIcon(item)}
            </div>
          {this.state.selectedMenu[item.category] && this.isMatch(this.state.selectedItem, item) && (
            <div className="panel-category-name clickable-item panel-item-text-selected mb-2"
                 onClick={(e: any) => this.handleNav(item)}>{item.name}</div>
          )}
              {this.state.selectedMenu[item.category] && !this.isMatch(this.state.selectedItem, item) && (
                <div className="panel-category-name clickable-item mb-2"
                     onClick={(e: any) => this.handleNav(item)}>{item.name}</div>
              )}
            </>
          )
        }
        return (
          <div key={item.name} className="clickable-item" data-abcd={item.name}>
          {_getCategory(item)}
          {this.isMatch(this.state.selectedItem, item) && (
            <div className="panel-item-selected" onClick={(e: any) => this.handleNav(item)}>
                <div className="icon-wrapper">
                  {item.icon ? item.icon : ''}
                </div>
                <div className="panel-item-text panel-item-text-selected">
                  <div className="item-name">
                    {item.name}
                  </div>
                </div>
              {item.hasFeature === false &&
                <div className="upgrade-icon-wrapper">
                  {upgradeIcon}
                </div>}
            </div>)}
          {!this.isMatch(this.state.selectedItem, item) && (
            <div className="panel-item" onClick={(e: any) => this.handleNav(item)}>
              <div className="icon-wrapper">
                <div className="icon">
                {item.icon ? item.icon : ''}
                </div>
              </div>
              <div className="panel-item-text clickable-item">
                {item.name}
              </div>
              {item.hasFeature === false
                && <div className="upgrade-icon-wrapper">
                  {upgradeIcon}
                </div>}
            </div>)}
        </div>)})
  }

  private showOption(option: PanelBarOption) {
    return !option.hide
  }

}
